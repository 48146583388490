;(function($) {

	if (!$("body").hasClass("single-cl_portfolio")) return;
	console.log("portfolio.js 1.1.1");


	
	// hacky shit that makes the name of the portfolio section appear under the Portfolio nav item

	var portfolioGroupName = $(".current-menu-parent .current-menu-item").text();
	$(".current-menu-parent").append("<span class='special-title'>"+portfolioGroupName+"</span>")




	// carousel stuff

	var slideCount = $('.carousel-item').length;
	console.log("slideCount", slideCount)

	if (slideCount == 1) {
		$(".carousel-control-prev").addClass("disabled");
		$(".carousel-control-next").addClass("disabled");
	}

	$('.portfolio-group-nav .total').text( slideCount );
	
	$('.carousel').carousel({
		ride: "false",
		wrap: false
	});
	$('.carousel').on('slid.bs.carousel', function() {
		console.log('slide change');
		var slideNumber = $('.carousel-item.active').index();
		console.log('slideNumber', slideNumber);
		$('.portfolio-group-nav .current').text(slideNumber + 1);

		if (slideNumber == 0) {
			$(".carousel-control-prev").addClass("disabled");
		} else {
			$(".carousel-control-prev").removeClass("disabled");
		}
		if (slideNumber == slideCount - 1) {
			$(".carousel-control-next").addClass("disabled");
		} else {
			$(".carousel-control-next").removeClass("disabled");
		}
	});

	var $carousel = document.getElementById('portfolio-carousel');
	var hammer = new Hammer.Manager($carousel);
	var swipe = new Hammer.Swipe();
	console.log(hammer);

	hammer.add(swipe);

	hammer.on('swipeleft', function() {
		console.log('swipeleft');
		$('.carousel-control-next').click();
	});

	hammer.on('swiperight', function() {
		console.log('swiperight');
		$('.carousel-control-prev').click();
	});

})(jQuery);