;(function($) {

	if (!$("body").hasClass("home")) return;
	console.log("home.js");


	$('.carousel').carousel();


})(jQuery);

