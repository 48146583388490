;(function($) {
	
	console.log("app.js 1.0");


	// initial setup for animations (hide all the things)
	TweenMax.set([".navbar-brand", ".navbar-nav > li", "body > section", "footer"], {opacity: 0});
	TweenMax.set([".navbar-brand"], {y: -30});
	TweenMax.set(["footer"], {y: 30});
	
	// show the body
	document.body.style.display = "block";
	

	// animations (show all the things in classy way)
	var appTimeline = new TimelineMax();
	appTimeline.to(".navbar-brand", 0.6, {opacity: 1, y: 0, delay: 0.1});
	
	if ($("body").hasClass("home")) {
		appTimeline.to(".navbar-nav > li:nth-child(2)", 0.6, {opacity: 1}, "nav+=0.2");
		appTimeline.to(".navbar-nav > li:nth-child(4)", 0.6, {opacity: 1}, "nav+=0.2");
		appTimeline.to(".navbar-nav > li:nth-child(1)", 0.6, {opacity: 1}, "nav+=0.4");
		appTimeline.to(".navbar-nav > li:nth-child(3)", 0.6, {opacity: 1}, "nav");
		appTimeline.to(".navbar-nav > li:nth-child(5)", 0.6, {opacity: 1}, "nav+=0.4");
	} else {
		appTimeline.to(".navbar-nav > li", 0.4, {opacity: 1});
	}
	
	appTimeline.to("body > section", 0.6, {opacity: 1});

	appTimeline.to("footer", 0.6, {y: 0, opacity: 1});
	

})(jQuery);
	